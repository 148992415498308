// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-de-js": () => import("./../src/pages/de.js" /* webpackChunkName: "component---src-pages-de-js" */),
  "component---src-pages-en-js": () => import("./../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-es-js": () => import("./../src/pages/es.js" /* webpackChunkName: "component---src-pages-es-js" */),
  "component---src-pages-fr-js": () => import("./../src/pages/fr.js" /* webpackChunkName: "component---src-pages-fr-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-js": () => import("./../src/pages/ja.js" /* webpackChunkName: "component---src-pages-ja-js" */),
  "component---src-pages-pt-js": () => import("./../src/pages/pt.js" /* webpackChunkName: "component---src-pages-pt-js" */),
  "component---src-pages-zh-js": () => import("./../src/pages/zh.js" /* webpackChunkName: "component---src-pages-zh-js" */),
  "component---src-templates-chapter-js": () => import("./../src/templates/chapter.js" /* webpackChunkName: "component---src-templates-chapter-js" */)
}

